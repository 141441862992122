import 'bootstrap';
//jquery
import $ from 'jquery';
// メインスライダー：vegas
// import './add/vegas/vegas.min.js';
// スクロールアップナビ
// import './add/jquery.bootstrap-autohidingnavbar.min.js';
// Readmore
// import './add/readmore.min.js';
// slick
// import './add/slick/slick.min.js';
// lightbox
import './add/lightbox/js/lightbox.js';
// iscroll.js
// import './add/iScroll/iscroll.js';
// Drawer.js
import './add/drawer/js/drawer.js';
// bxSlider
import './add/bxslider/jquery.bxslider.min.js';
// smoothScroll
import './add/smoothScroll/jquery.smoothScroll.js';
// inview
import './add/jquery_inview/jquery.inview.min.js';
// 実行スクリプト集
import './myscript.js';
// sassコンパイル
import './index.scss';