$(function () {
    /* ナビゲーション高さ分のpadding-topをbodyに設定 */
    // var nav_height = $('.navbar').outerHeight();
    // var nav_size = nav_height + 'px';
    // // //alert( nav_size );
    // $('body').css('padding-top', nav_size);

    // ページ内リンクをクリック時の.navbar-collapse非表示
    var collapse_menu = $('.navbar-nav li a').not('.dropdown-toggle');
    $(collapse_menu).click(function () {
        $('.navbar-collapse').collapse('hide');
    });
});

/* スクロールアップナビの実行 */

// $('.fixed-top').autoHidingNavbar();
// $(window).scroll(function () {
//     var header = $('#g-nav');
//     var scroll = $(document).scrollTop();
//     if (scroll >= 100) {
//         header.addClass('is-black');
//     } else {
//         header.removeClass('is-black');
//     }
// });
// スクロール時にナビを上部固定
$(window).scroll(function () {
    if ($(this).scrollTop() > $('nav#global-nav').position().top) {
        $('nav#global-nav').addClass('fixed-top');
        //$('body').addClass('pt-60');
        $('#navbar-brand').removeClass('d-none');
    } else {
        $('nav#global-nav').removeClass('fixed-top');
        //$('body').removeClass('pt-60');
        $('#navbar-brand').addClass('d-none');
    }
});



/* ハンバーガーメニューが本文に重なったら影をつける */
function init() {
    // スクロールして何ピクセルでアニメーションさせるか
    var px_change  = 100;
    // スクロールのイベントハンドラを登録
    window.addEventListener('scroll', function(e){
        // 変化するポイントまでスクロールしたらクラスを追加
        if ( $(window).scrollTop() > px_change ) {
            $("#drawer-hamburger").addClass("shadow-sm");
            // 変化するポイント以前であればクラスを削除
        } else if ( $("#drawer-hamburger").hasClass("shadow-sm") ) {
            $("#drawer-hamburger").removeClass("shadow-sm");
        }
    });
}
window.onload = init();

/* 移動＋フェードイン表示 */
$(function () {
    $('.iv-up-fade').on('inview', function (event, isInView, visiblePartX, visiblePartY) {
        if (isInView) {
            $(this).stop().addClass('mv-up-fade');
        } else {
            $(this).stop().removeClass('mv-up-fade');
        }
    });
});
/* bxスライダー */
$(document).ready(function() {
    $('.bxslider').bxSlider({
        auto: true,
        mode: 'fade',
        speed: 1000,
        controls: false
    });
});
//単純なタイプ、ページ内リンクだけの場合は基本これでＯＫ
$(document).on('mousedown','.bx-viewport a',function(){
    window.location.href = $(this).attr('href');
  });

/* drawer メニュー */
$(document).ready(function() {
    $('.drawer').drawer();
});

/* smoothScroll */
$('a').smoothScroll();


/* vegasスライダー */
// $(function() {
//     $('.jumbotron-fluid').vegas({ //背景画像でスライドショーしたい場所の設定
//         slides: [{
//                 src: 'img/slide-2_13_2.jpg'
//             }, //スライドする画像を配列で設定
//             {
//                 src: 'img/slide-13_71.jpg'
//             },
//             {
//                 src: 'img/slide-3_30.jpg'
//             },
//             {
//                 src: 'img/slide-19_110.jpg'
//             },
//             {
//                 src: 'img/slide-22_1.jpg'
//             }
//         ],
//         delay: 5000, //スライドまでの時間ををミリ秒単位で設定
//         timer: false, //タイマーバーの表示/非表示を切り替え
//         //overlay: 'img/01.png', //オーバーレイする画像の設定
//         animation: 'random', //スライドのアニメーションを設定
//         transition: 'blur', //スライド間のエフェクトを設定
//         transitionDuration: 1000, //エフェクト時間をミリ秒単位で設定
//         //overlay: true
//     });
// });

/* Readmore */
// jQuery(function () {
//     $('.open').readmore({
//         speed: 750,
//         collapsedHeight: 105,
//         moreLink: '<a class="btn" href="#"><span>続きを読む</span></a>',//続きを読むボタンタグ
//         lessLink: '<a class="btn_close" href="#"><span>閉じる</span></a>'//閉じるボタンタグ
//     });
// });

/* slickスライダー */
// $('.slick-slider').slick({
//     autoplay:true,
//     autoplaySpeed:5000,
//     dots: true,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 1,
//     centerMode: true,
//     variableWidth: true
// });

/* lightbox */
// lightbox.option({
//     'resizeDuration': 200,
//     'wrapAround': true
//   });

  